import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Util from 'utility';
import api from 'api';
import { Locale, __ } from 'locale';


import {
    setAppSubMode,
    setLectureTaskComplete,
    openModal,
    closeModal
} from 'actions/index';

import TaskEvaluator from './TaskEvaluator';
import EvaluationPanel from 'components/EvaluationPanel';
import { isAfter } from 'date-fns';

const mapStateToProps = state => {
    return { data: state };
}

const mapDispatchToProps = dispatch => {
    return {
        setAppSubMode: data => dispatch(setAppSubMode(data)),
        setLectureTaskComplete: data => dispatch(setLectureTaskComplete(data)),
        openModal: (data) => dispatch(openModal(data)),
        closeModal: (data) => dispatch(closeModal(data)),
    };
};

class LectureTaskPlan extends Component {

    constructor(props) {
        super(props);

        this.openLectureTaskSampleMenu = this.openLectureTaskSampleMenu.bind(this);
        this.showTaskListDDDInfoFunc = this.showTaskListDDDInfoFunc.bind(this);
        this.seminarWorkChangeDay = this.seminarWorkChangeDay.bind(this);
    }

    openLectureTaskSampleMenu() {
        const sampleLectureTaskId = this.props.data.appModeData.lectureInfoData.lecture_data.sample_lecturetask_id;

        let id = sampleLectureTaskId;
        id = 8800000 + id * 7;      // TODO: this is shitty as fuck, at least make a hash or something
                                    // (update in lecturetask/load API call too)

        this.props.setAppSubMode('LECTURETASK_SAMPLE');
        this.props.history.push('/load/task/' + id + '/' + this.props.data.appModeData.lectureId);
    }

    seminarWorkChangeDay(l, e) {
        this.props.history.push('/load/seminarwork-task/' + l + '/' + l);
    }

    showTaskListDDDInfoFunc(info, id, type) {
        let content = [];
        let mealsParts = {};

        let data = this.props.data;

        if(type === 'category') {
            if(typeof info['categories'][id] !== 'undefined') {
                let tmp = {};
                info['categories'][id].map(f => {
                    const mk = f.meal + '|' + f.meal_id;
                    if(typeof tmp[mk] === 'undefined') tmp[mk] = {};
                    if(typeof tmp[mk][f.baseFood.title] === 'undefined') tmp[mk][f.baseFood.title] = [];                   
                    if(f.baseFood.title !== f.food.title) {
                        tmp[mk][f.baseFood.title].push(f.food.title);
                    }
                });

                Object.keys(data.globals.mealTypes).map(m => {
                    const mt = data.globals.mealTypes[m].title;
                    const mk = mt + '|' + m;
                    if(typeof tmp[mk] !== 'undefined') {
                        content.push(<p><b>{mt}</b></p>);
                        let subresult = [];
                        Object.keys(tmp[mk]).map(fd => {
                            const subFoods = tmp[mk][fd];
                            if(subFoods.length > 0) {
                                subresult.push(<p>{fd}: {subFoods.join(', ')}</p>);
                            } else {
                                subresult.push(<p>{fd}</p>);
                            }
                        });
                        content.push(<div>{subresult}</div>);
                    }
                });

                content = <div className="lecturetask-foodslist">{content}</div>;
            }
        

            let titlePart = 'Chody/potraviny obsahující'

            this.props.closeModal();
            this.props.openModal({
                title: __('Chody/potraviny obsahující') + ' "' + this.props.data.globals.foodCategories[id].title + '"',
                closeButton: __('Zavřít'),
                class: 'dialog-flexible',
                content: content
            });
        }


        if(type === 'tag') {

            if(typeof info['tags'][id] !== 'undefined') {
                let tmp = {};
                info['tags'][id].map(f => {
                    const mk = f.meal + '|' + f.meal_id;
                    if(typeof tmp[mk] === 'undefined') tmp[mk] = {};
                    if(typeof tmp[mk][f.baseFood.title] === 'undefined') tmp[mk][f.baseFood.title] = [];                   
                    if(f.baseFood.title !== f.food.title) {
                        tmp[mk][f.baseFood.title].push(f.food.title);
                    }
                });

                Object.keys(data.globals.mealTypes).map(m => {
                    const mt = data.globals.mealTypes[m].title;
                    const mk = mt + '|' + m;
                    if(typeof tmp[mk] !== 'undefined') {
                        content.push(<p><b>{mt}</b></p>);
                        let subresult = [];
                        Object.keys(tmp[mk]).map(fd => {
                            const subFoods = tmp[mk][fd];
                            if(subFoods.length > 0) {
                                subresult.push(<p>{fd}: {subFoods.join(', ')}</p>);
                            } else {
                                subresult.push(<p>{fd}</p>);
                            }
                        });
                        content.push(<div>{subresult}</div>);
                    }
                });

                content = <div className="lecturetask-foodslist">{content}</div>;
            }
        
            this.props.closeModal();
            this.props.openModal({
                title: 'Chody/potraviny obsahujicí "' + this.props.data.globals.foodTags[id] + '"',
                closeButton: __('Zavřít'),
                class: 'dialog-flexible',
                content: content
            });
        }
        
    }

    render() {
        const { data } = this.props;
        const { globals } = data;
        const taskData = data.lectureTaskData;

        let evaluationPanelBeforeContent = false;

        let profileId = Util.getActiveProfileId();
        if(typeof data.appModeData.profileId === 'number') {
            profileId = data.appModeData.profileId;
        }

        let evalResults = false;
        let mealsResults = false;
        if(typeof data.evaluationResults === 'object') {
            evalResults = data.evaluationResults.results;
            mealsResults = data.mealsEvaluationResults;
        }

        let tasksList = [];
        let taskCompletedInfo = false;

        if(typeof taskData.lecture_data === 'object') {

            const evaluator = new TaskEvaluator({
                'dddInfo': this.showTaskListDDDInfoFunc
            });
            const res = evaluator.evaluate({lectureInfo: this.props.lectureInfo});

            let taskListMeals = res.taskListMeals;
            let taskListMealsOk = res.taskListMealsOk;
            let taskListUndesirableFoodsUsed = res.taskListUndesirableFoodsUsed;
            let taskListNumberOfFoods = res.taskListNumberOfFoods;
            let taskListNumberOfFoodsOk = res.taskListNumberOfFoodsOk;
            let taskListDDD = res.taskListDDD;
            let taskListDDDOk = res.taskListDDDOk;
            let taskListDDDInfo = res.taskListDDDInfo;

            if(taskListMeals.length > 0 || taskListMealsOk.length > 0) {
                
                let taskListMealsEl = (
                    <>
                    {taskListMeals}
                    {taskListMealsOk}
                    </>
                );

                if(Util.isCurrentTaskSeminarWorkAssignment()) {
                    taskListMealsEl = (
                        <div className="tasklist-wrapped-seminarwork">
                        {taskListMeals}
                        {taskListMealsOk}
                        </div>
                    );

                }

                let box = (
                    <div className="lecturetask-eval-box">
                        <div class="lt-heading">
                            <h3>{__('Chody / chuť')}</h3>
                            <label htmlFor="lt-cb1">?</label>
                            <input type="checkbox" id="lt-cb1" />
                            <div class="lt-help">
                                <p>Vyvořte u každého chodu recept/y odpovídající zadané chuti.</p>
                            </div>
                        </div>
                        {taskListMealsEl}
                    </div>
                );

                tasksList.push(box);
            }

            if(taskListNumberOfFoods.length > 0 || taskListNumberOfFoodsOk.length > 0) {

                let box = (
                    <div className="lecturetask-eval-box">
                        <div class="lt-heading">
                            <h3>{__('Pestrost')}</h3>
                            <label htmlFor="lt-cb2">?</label>
                            <input type="checkbox" id="lt-cb2" />
                            <div class="lt-help">
                                <p>Vámi vytvořený jídelníček musí obsahovat požadované počty zástupců potravin z jednotlivých kategorií.</p>
                            </div>
                        </div>
                        {taskListNumberOfFoods}
                        {taskListUndesirableFoodsUsed}
                        {taskListNumberOfFoodsOk}
                    </div>
                );

                tasksList.push(box);
            }


            if(taskListDDD.length > 0 || taskListDDDOk.length > 0) {
                let box = (
                    <div className="lecturetask-eval-box">
                        <div class="lt-heading">
                            <h3>{__('Nutriční hodnoty')}</h3>
                            <label htmlFor="lt-cb3">?</label>
                            <input type="checkbox" id="lt-cb3" />
                            <div class="lt-help">
                                <p>Vámi vytvořený jídelníček musí splňovat požadované nutriční hodnoty.</p>
                            </div>
                        </div>
                        {taskListDDD}
                        {taskListDDDOk}
                    </div>
                );
                tasksList.push(box);
            }

            //

            let allFoodGroupsHaveNames = true;
            let allFoodGroupsHaveDescriptions = true;

            let mealDescriptions = [];

            Object.keys(this.props.data.foodGroups).map(m => {
                let ma = m.split('|');
                if(ma[0] === "0") return;
                const fgm = this.props.data.foodGroups[m];
                Object.keys(fgm).map(fg => {
                    const g = fgm[fg];
                    if(g.title.trim() == '') allFoodGroupsHaveNames = false;

                    if(typeof g.description === 'string') {
                        if(g.description.trim() != '') mealDescriptions.push(ma[0]*1);
                    }
                });
            });

            if(!mealDescriptions.includes(1)) allFoodGroupsHaveDescriptions = false;
            if(!mealDescriptions.includes(3)) allFoodGroupsHaveDescriptions = false;
            if(!mealDescriptions.includes(5)) allFoodGroupsHaveDescriptions = false;

            let allFoodGroupsMustHaveNamesWarning = false;
            let allFoodGroupsMustHaveDescriptionsWarning = false;

            // Is task completed?

            const isCurrentlyCompleted = this.props.data.lectureTaskComplete;

            const liData = this.props.data.appModeData.lectureInfoData;

            // ZOF custom settings in lecture admin - 
            // if "one_food_to_complete_task" is active:
            if((typeof liData.lecture_data.custom_settings !== 'undefined') &&
               (typeof liData.lecture_data.custom_settings === 'object') &&
               liData.lecture_data.custom_settings !== null && 
                       liData.lecture_data.custom_settings.includes('one_food_to_complete_task')) {
                let foodsNum = 0;
                Object.keys(this.props.data.foods).map(f => {
                    foodsNum += this.props.data.foods[f].length;
                });
                if(foodsNum > 0) {
                    if(isCurrentlyCompleted !== true) {
                        this.props.setLectureTaskComplete(true);
                    }
                }

            } else {

                if(
                    taskListMeals.length === 0 && 
                    taskListNumberOfFoods.length === 0 && 
                    taskListDDD.length === 0 &&
                    taskListUndesirableFoodsUsed.length === 0
                ) {
                    // Splneno zadani, ale jeste zkontrolovat nazvy,
                    // popr. popisy pripravy u vypracovani seminarek

                    if(Util.isCurrentTaskSeminarWork() && !Util.isCurrentTaskSeminarWorkAssignment()) {
                        // Pokud je aktualni mod SEMINARKA VYPRACOVANI
                        // musi byt vyplnene nazvy i popisy taliru

                        if(allFoodGroupsHaveNames && allFoodGroupsHaveDescriptions) {
                            if(isCurrentlyCompleted !== true) {
                                this.props.setLectureTaskComplete(true);
                            }
                        } else {
                            if(!allFoodGroupsHaveNames) {
                                allFoodGroupsMustHaveNamesWarning = <p style={{margin:'1em 0 0',fontWeight:'bold',textAlign:'center',color:'#f00'}}>
                                        <span style={{display:'inline-block',lineHeight:'100%',padding:'6px 8px',borderRadius:'4px',background:'#fff'}}>
                                            {__('Doplňte názvy ke všem receptům!')}
                                        </span>
                                    </p>;
                            }
                            if(!allFoodGroupsHaveDescriptions) {
                                allFoodGroupsMustHaveDescriptionsWarning = <p style={{margin:'1em 0 0',fontWeight:'bold',textAlign:'center',color:'#f00'}}>
                                        <span style={{display:'inline-block',lineHeight:'100%',padding:'6px 8px',borderRadius:'4px',background:'#fff'}}>
                                            {__('Doplňte popisy přípravy ke všem receptům!')}
                                        </span>
                                    </p>;
                            }
                            
                            if(isCurrentlyCompleted !== false) {
                                this.props.setLectureTaskComplete(false);
                            }
                        }

                    } else {
                        // Klasicky mod vypracovani ukolu
                        if(allFoodGroupsHaveNames) {
                            if(isCurrentlyCompleted !== true) {
                                this.props.setLectureTaskComplete(true);
                            }
                        } else {
                            allFoodGroupsMustHaveNamesWarning = <p style={{margin:'1em 0 0',fontWeight:'bold',textAlign:'center',color:'#f00'}}>
                                    <span style={{display:'inline-block',lineHeight:'100%',padding:'6px 8px',borderRadius:'4px',background:'#fff'}}>
                                        {__('Doplňte názvy ke všem receptům!')}
                                    </span>
                                </p>;
                            if(isCurrentlyCompleted !== false) {
                                this.props.setLectureTaskComplete(false);
                            }
                        }
                    }

                } else {
                    // Nesplneno zadani
                    if(isCurrentlyCompleted !== false) {
                        this.props.setLectureTaskComplete(false);
                    }
                }

            }


            // let completedInfoText = <p style={{margin:0}}>Nezapomeňte jídelníček <b>uložit</b>!</p>;
            //if(true || this.props.data.lectureTaskCompleteSaved) {
            let completedInfoText = (
                <>
                <p style={{fontWeight:'bold',fontSize:'.9em',color:'#f00',margin:'-.25em 0 1.75em 0'}}>{__('Po vyplnění jídelníčku nezapomeňte úkol uložit tlačítkem Uložit úkol!')}</p>
                <p><a style={{background:'#26AA52',color:'#fff',padding:'.85em 2em',textDecoration:'none',fontWeight:'bold',borderRadius:'5px'}} onClick={() => this.openLectureTaskSampleMenu()}>Otevřít vzorový jídelníček</a></p>
                </>
            );

            if(Util.getLang() == 'sk') {
                completedInfoText = (
                    <>
                    <p style={{fontWeight:'bold',fontSize:'.9em',color:'#f00',margin:'-.25em 0 1.75em 0'}}>Nezabudnite úlohu uložiť<br />tlačidlom "Uložiť úlohu"!</p>
                    <p><a style={{background:'#26AA52',color:'#fff',padding:'.85em 2em',textDecoration:'none',fontWeight:'bold',borderRadius:'5px'}} onClick={() => this.openLectureTaskSampleMenu()}>Otvoriť vzorový jedálniček</a></p>
                    </>
                );
            }

            //}


            // If this task is from UNIVERSAL COURSE, do not show link to example menu
            if(typeof this.props.data.appModeData.lectureInfoData.course_id === 'number') {
                if(this.props.data.appModeData.lectureInfoData.course_id === 46) {
                    completedInfoText = false;
                }
            }

            // If this task is from SEMINAR WORK, show only "save your lecturetask" warning
            if(Util.isCurrentTaskSeminarWork()) {
                completedInfoText = (
                    <>
                    <p style={{fontWeight:'bold',fontSize:'.9em',color:'#f00',margin:'-.25em 0 1.75em 0'}}>{__('Po vyplnění jídelníčku nezapomeňte úkol uložit tlačítkem Uložit úkol!')}</p>
                    </>
                );
            }


            let wellDone = (
                <>
                <p><i className="fa fa-check"></i></p>
                <p class="cg"><b>Výborně! Váš úkol je splněn.</b></p>
                {completedInfoText}
                </>
            );

            if(Util.getLang() == 'sk') {
                wellDone = (
                    <>
                    <p><i className="fa fa-check"></i></p>
                    <p class="cg"><b>Výborne! Vaša úloha je splnená.</b></p>
                    {completedInfoText}
                    </>
                );
            }

        
            // ZOF custom settings in lecture admin - 
            // if "one_food_to_complete_task" is active,
            // show different "well done" message
            if((typeof liData.lecture_data.custom_settings !== 'undefined') &&
               (typeof liData.lecture_data.custom_settings === 'object') &&
               liData.lecture_data.custom_settings !== null && 
                       liData.lecture_data.custom_settings.includes('one_food_to_complete_task')) {
                
                    wellDone = (
                        <>
                        <p class="cg"><b>{__('Po vyplnění jídelníčku nezapomeňte úkol uložit tlačítkem Uložit úkol!')}</b></p>
                        </>
                    );
            }




            if(this.props.data.appSubMode !== 'LECTURETASK_SAMPLE') {
                if(isCurrentlyCompleted) {
                    taskCompletedInfo = (
                        <div className="lecturetask-complete">
                            {wellDone}
                        </div>
                    );
                }
            }

            let seminarWorkData = this.props.data.appModeData?.lectureInfoData?.seminar_work_data;
            if(typeof seminarWorkData === 'object' && seminarWorkData.is_assignment === 1) {
                taskCompletedInfo = false;
            }
    
            // Watch ZOF attributs ("Sledujte" pri zadavani ukolu u lekce)

            let watchAttribs = [];
            let graph = false;

            let showRI = [
                'potassium', 'calcium', 'magnesium', 'sodium', 'iodine', 'selenium', 'zinc', 'iron', 'iron_hem', 'phosphorus', 'zinc',
                'vitamin_c', 'vitamin_d', 'vitamin_e', 'vitamin_d2', 'vitamin_d3', 'vitamin_a', 'vitamin_b1', 'vitamin_b2', 'vitamin_b3',
                'vitamin_b5', 'vitamin_b6', 'vitamin_b9', 'vitamin_b12', 'vitamin_k'
            ];

            // Show ratio, if both of relevant values are shown before (in "Nutricni hodnoty" part)

            let showRatios = {
                'proteins_ratio': {
                    'after': 'proteins_plant',
                    'depends_on': ['proteins_animal', 'proteins_plant'],
                },
                'fats_saturated_unsaturated_ratio': {
                    'after': 'fats_unsaturated_total',
                    'depends_on': ['fats_saturated', 'fats_unsaturated_total'],
                },
                'fats_monounsaturated_polyunsaturated_ratio': {
                    'after': 'fats_polyunsaturated',
                    'depends_on': ['fats_monounsaturated', 'fats_polyunsaturated'],
                },
                'fats_omega3_omega6_ratio': {
                    'after': 'fats_omega6',
                    'depends_on': ['fats_omega3', 'fats_omega6'],
                },
                'fats_omega3_dha_epa_omega6_ratio': {
                    'after': 'fats_omega6',
                    'depends_on': ['fats_omega3_dha_epa', 'fats_omega6'],
                },
                'fiber_ratio': {
                    'after': 'fiber_soluble',
                    'depends_on': ['fiber_soluble', 'fiber_insoluble'],
                },
                'calcium_magnesium_ratio': {
                    'after': 'magnesium',
                    'depends_on': ['calcium', 'magnesium'],
                },
                'potassium_sodium_ratio': {
                    'after': 'sodium',
                    'depends_on': ['potassium', 'sodium'],
                },
                'calcium_phosphorus_ratio': {
                    'after': 'phosphorus',
                    'depends_on': ['calcium', 'phosphorus'],
                },
            };

            // First check which ratios should be filled

            if(typeof evalResults !== 'undefined') {
                if(Array.isArray(Object.keys(taskData.ddd_limits))) {
                    Object.keys(taskData.ddd_limits).map(attr => {
                        if(attr == '') return;
                        if(attr === 'fats_unsaturated') attr = 'fats_unsaturated_total';
                        Object.keys(showRatios).map(r => {
                            if(showRatios[r].depends_on.includes(attr)) {
                                showRatios[r].depends_on = showRatios[r].depends_on.filter(a => a !== attr);
                            }
                        });
                    });
                }
            }

            // Fill the watchAttribs array

            let watchAttribsAlreadyFilled = [];
            let watchAttribsByKey = {};
            let currEl;

            let watchAttrs = data.appModeData.watchAttributes;
            if(typeof watchAttrs === 'object') {
                if(Util.isCurrentTaskSeminarWork()) {
                    watchAttrs.push('weight');
                    watchAttrs.push('drink_amount');
                }
            }

            let energyAmountText = 'Množství energie';
            if(Util.getLang() == 'sk') energyAmountText = 'Množstvo energie';

            if(typeof data.evaluationResults !== 'undefined') {
                if(typeof data.evaluationResults.results !== 'undefined') {

                    const thisMealIsEmpty = data.evaluationResults.results.weight === 0 && data.evaluationResults.results.energy_kcal === 0;

                    Object.keys(data.evaluationResults.results).map(attr => {
                    //data.evaluationResults.results.map(attr => {
                        if(attr == '') return;
                        if(attr === 'fats_unsaturated') attr = 'fats_unsaturated_total';
                        if(attr === 'energy_balance_kcal') attr = 'kcal_kj';

                        if(typeof watchAttrs === 'object') {
                            if(watchAttrs.includes(attr)) {

                        //if(typeof data.appModeData.watchAttributes === 'object') {
                            //if(data.appModeData.watchAttributes.includes(attr)) {

                                if(attr == 'kcal_kj') {
                                    let kcal = data.evaluationResults.results['energy_balance_kcal'] + '';
                                    let kj = data.evaluationResults.results['energy_balance_kj'] + '';
                                    if(kcal > 0 && kcal.substr(0, 1) !== '+') kcal = '+' + kcal;
                                    if(kj > 0 && kj.substr(0, 1) !== '+') kj = '+' + kj;

                                    currEl = (
                                        <>
                                        <div className="watch-attribute-row">
                                            <p>{energyAmountText}:</p>
                                            <aside>
                                                {data.evaluationResults.results['energy_kcal'] * 1}
                                                {'\u00A0'}
                                                kcal
                                            </aside>
                                        </div>
                                        </>
                                    );
                                    watchAttribs.push(currEl);
                                    watchAttribsByKey['energy_kcal'] = currEl;

                                    currEl = (
                                        <>
                                        <div className="watch-attribute-row">
                                            <p>{energyAmountText}:</p>
                                            <aside>
                                                {data.evaluationResults.results['energy_kj']}
                                                {'\u00A0'}
                                                kJ
                                            </aside>
                                        </div>
                                        </>
                                    );
                                    watchAttribs.push(currEl);
                                    watchAttribsByKey['energy_kj'] = currEl;

                                    currEl = (
                                        <>
                                        <div className="watch-attribute-row">
                                            <p>{data.globals.attributes['energy_balance_kcal']}:</p>
                                            <aside>
                                                {kcal}
                                                {'\u00A0'}
                                                {data.globals.attribute_units['energy_balance_kcal']}
                                            </aside>
                                        </div>
                                        </>
                                    );
                                    watchAttribs.push(currEl);
                                    watchAttribsByKey['energy_balance_kcal'] = currEl;

                                    currEl = (
                                        <>
                                        <div className="watch-attribute-row">
                                            <p>{data.globals.attributes['energy_balance_kj']}:</p>
                                            <aside>
                                                {kj}
                                                {'\u00A0'}
                                                {data.globals.attribute_units['energy_balance_kj']}
                                            </aside>
                                        </div>
                                        </>
                                    );
                                    watchAttribs.push(currEl);
                                    watchAttribsByKey['energy_balance_kj'] = currEl;

                                } else {

                                    let resval, textEval;

                                    switch(attr) {
                                        
                                        case 'gi':

                                            resval = data.evaluationResults.results[attr]; 
                                            textEval = 'nízký';
                                            if(resval > 50) textEval = 'střední';
                                            if(resval > 70) textEval = 'vysoký';

                                            if(thisMealIsEmpty) textEval = '-';

                                            currEl =(
                                                <div className="watch-attribute-row">
                                                    <p>{data.globals.attributes[attr]}:</p>
                                                    <aside>
                                                        {textEval}
                                                    </aside>
                                                </div>
                                            );
                                            watchAttribs.push(currEl);
                                            watchAttribsByKey[attr] = currEl;
                                            watchAttribsAlreadyFilled.push(attr);
                                            break;

                                        case 'gn':

                                            // Toto je celodenni vyhodnoceni 
                                            // tj. limity pro GN jsou jine nez pro mod jednoho receptu

                                            resval = data.evaluationResults.results[attr]; 
                                            textEval = 'nízká';
                                            if(resval > 80) textEval = 'střední';
                                            if(resval > 119) textEval = 'vysoká';

                                            if(thisMealIsEmpty) textEval = '-';

                                            currEl = (
                                                <div className="watch-attribute-row">
                                                    <p>{data.globals.attributes[attr]}:</p>
                                                    <aside>
                                                        {textEval}
                                                    </aside>
                                                </div>
                                            );
                                            watchAttribs.push(currEl);
                                            watchAttribsByKey[attr] = currEl;
                                            watchAttribsAlreadyFilled.push(attr);
                                            break;

                                        default:

                                            if(['acidobasicity','digestibility','energy','thermic_effect','thermiceffect'].includes(attr)) {

                                                let attrFinal = attr;
                                                if(attrFinal === 'energy') attrFinal = 'energytendency';
                                                if(attrFinal === 'thermic_effect') attrFinal = 'thermiceffect';

                                                let opts = data.globals.evaluationTexts[attrFinal];

                                                let text = opts[Math.round(data.evaluationResults.results[attr + '_n'])];
                                            
                                                if(thisMealIsEmpty) text = '-';


                                                currEl = (
                                                    <div className="watch-attribute-row">
                                                        <p>{data.globals.attributes[attr]}:</p>
                                                        <aside>
                                                            {text}
                                                            {'\u00A0'}
                                                            {data.globals.attribute_units[attr]}
                                                        </aside>
                                                    </div>
                                                );
                                                watchAttribs.push(currEl);
                                                watchAttribsByKey[attr] = currEl;    
                                                watchAttribsAlreadyFilled.push(attr);

                                            } else {

                                                if(showRI.includes(attr)) {

                                                    if(typeof data.evaluationResults.ddd[attr] !== 'undefined') {
                                                        let RIpct = data.evaluationResults.ddd[attr].percent;
                                                        currEl = (
                                                            <div className="watch-attribute-row">
                                                                <p>{data.globals.attributes[attr]}:</p>
                                                                <aside>
                                                                    {RIpct}
                                                                    {'\u00A0'}
                                                                    % RI
                                                                </aside>
                                                            </div>
                                                        );
                                                        watchAttribs.push(currEl);
                                                        watchAttribsByKey[attr] = currEl;            
                                                        watchAttribsAlreadyFilled.push(attr);
                                                    }

                                                } else {

                                                    currEl = (
                                                        <div className="watch-attribute-row">
                                                            <p>{data.globals.attributes[attr]}:</p>
                                                            <aside>
                                                                {data.evaluationResults.results[attr]}
                                                                {'\u00A0'}
                                                                {data.globals.attribute_units[attr]}
                                                            </aside>
                                                        </div>
                                                    );
                                                    watchAttribs.push(currEl);
                                                    watchAttribsByKey[attr] = currEl;        
                                                    watchAttribsAlreadyFilled.push(attr);
                
                                                    if(attr == 'added_sugars') {
                                                        let sugarCubes = data.evaluationResults.results[attr] / 4;
                                                        sugarCubes = Math.ceil(sugarCubes * 10) / 10;
                                                        currEl = (
                                                            <div className="watch-attribute-row">
                                                                <p>- odpovídá kostkám cukru:</p>
                                                                <aside>
                                                                    {sugarCubes}
                                                                </aside>
                                                            </div>
                                                        );
                                                        watchAttribs.push(currEl);
                                                        watchAttribsByKey['added_sugar_cubes'] = currEl;
            
                                                    }

                                                }

                                            }
                                            break;
                                    }

                                }
                            }
                        }

                        Object.keys(showRatios).map(r => {
                            if(data.appModeData.watchAttributes.includes(r)) return;
                            if(showRatios[r].after === attr && showRatios[r].depends_on.length === 0) {
                                currEl = (
                                    <>
                                    <div className="watch-attribute-row">
                                        <p>{data.globals.attributes[r]}:</p>
                                        <aside>
                                            {data.evaluationResults.results[r]}
                                        </aside>
                                    </div>
                                    </>
                                );
                                watchAttribs.push(currEl);
                                watchAttribsByKey[attr] = currEl;
                                watchAttribsAlreadyFilled.push(r);
                            }
                        });

                    });

                    // Energy ratio chart

                    if(watchAttribs.length > 0) {

                        let results = data.evaluationResults.results;
                        let chartColours = ["#96c999", "#eb714a", "#eae869", "#8bcbce", "#c694be"];

                        let enProt = results["proteins"] * 4.18;
                        let enCarb = results["carbohydrates"] * 4.18;
                        let enFats = results["fats"] * 9;
                        let enFibr = results["fiber"] * 2;

                        let total = enProt + enCarb + enFats + enFibr;
                        if (total == 0) total = 0.00001;

                        enProt = Math.round((enProt / total) * 100);
                        enCarb = Math.round((enCarb / total) * 100);
                        enFats = Math.round((enFats / total) * 100);
                        enFibr = 100 - enProt - enCarb - enFats; //(enFibr / total) * 100;

                        let chart2 = Util.pieChart(
                            [enProt, enCarb, enFats, enFibr],
                            100,
                            100,
                            95,
                            chartColours
                        );

                        graph = (
                            <>
                            <div className="lecturetask-eval-box">
                                <div class="lt-heading">
                                    <h3>{__('Energetické poměry makroživin')}</h3>
                                    <label htmlFor="lt-cb5">?</label>
                                    <input type="checkbox" id="lt-cb5" />
                                    <div class="lt-help">
                                        <p>Ve vytvořeném jídelníčku sledujte poměry makroživin hodnocení v jejich procentuálním podílu z celkového příjmu energie.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="eval-item">
                            <div className="svg-wrap">
                                <svg viewBox="0 0 400 200" width="100%" height="100%">
                                {chart2}
                                <rect
                                    x="220"
                                    y="40"
                                    width="20"
                                    height="20"
                                    style={{ fill: chartColours[0], strokeWidth: 0 }}
                                />
                                <text fontSize="1.4em" x="250" y="55" fill="#444">
                                    {__('Bílkoviny')} ({parseFloat(enProt)} %)
                                </text>
                                <rect
                                    x="220"
                                    y="70"
                                    width="20"
                                    height="20"
                                    style={{ fill: chartColours[1], strokeWidth: 0 }}
                                />
                                <text fontSize="1.4em" x="250" y="85" fill="#444">
                                    {__('Sacharidy')} ({parseFloat(enCarb)} %)
                                </text>
                                <rect
                                    x="220"
                                    y="100"
                                    width="20"
                                    height="20"
                                    style={{ fill: chartColours[2], strokeWidth: 0 }}
                                />
                                <text fontSize="1.4em" x="250" y="115" fill="#444">
                                    {__('Tuky')} ({parseFloat(enFats)} %)
                                </text>
                                <rect
                                    x="220"
                                    y="130"
                                    width="20"
                                    height="20"
                                    style={{ fill: chartColours[3], strokeWidth: 0 }}
                                />
                                <text fontSize="1.4em" x="250" y="145" fill="#444">
                                    {__('Vláknina')} ({parseFloat(enFibr)} %)
                                </text>
                                </svg>
                            </div>
                            </div>
                            </>
                        );
                    }
                }
            }

            evaluationPanelBeforeContent = (
                <>
                {allFoodGroupsMustHaveNamesWarning}
                {allFoodGroupsMustHaveDescriptionsWarning}
                {taskCompletedInfo}
                <div style={{padding:'0 1rem',fontSize:'12px'}}>
                    <div className="lecturetask-taskslist">{tasksList}</div>
                </div>
                </>
            );


            let watchAttribsSorted = [];
        
            let attributeKey, attributeUnit;
            for (let groupKey in this.props.data.globals.evaluation_groups_opened) {
                let subItems = [];
                for (let subGroup in globals.evaluation_groups[groupKey]) {
                  attributeKey = globals.evaluation_groups[groupKey][subGroup];
                  attributeUnit = globals.attribute_units[attributeKey];
                  if (typeof watchAttribsByKey[attributeKey] == "undefined") continue;
                  subItems.push(watchAttribsByKey[attributeKey]);
                }
                if (subItems.length > 0) {
                  watchAttribsSorted.push(
                      <div className="lecturetask-watch-group">
                        <input id={`watchgrp_${groupKey}`} type="checkbox" />
                        <label htmlFor={`watchgrp_${groupKey}`}>
                            <i className="fa fa-angle-right"></i>
                            {groupKey}
                        </label>
                        <div className="wcontent">{subItems}</div>
                      </div>
                  );
                }
            }

            if(watchAttribs.length > 0) {
                evaluationPanelBeforeContent = (
                    <>
                    {allFoodGroupsMustHaveNamesWarning}
                    {allFoodGroupsMustHaveDescriptionsWarning}
                    {taskCompletedInfo}
                        <div style={{padding:'0 1rem',fontSize:'12px'}}>
                        <div className="lecturetask-taskslist">{tasksList}</div>
                        <div className="lecturetask-eval-box">
                            <div class="lt-heading">
                                <h3>Sledujte</h3>
                                <label htmlFor="lt-cb4">?</label>
                                <input type="checkbox" id="lt-cb4" />
                                <div class="lt-help">
                                    <p>Ve vytvořeném jídelníčku sledujte vybrané nutriční hodnoty. Tyto slouží jako informační doplněk k hodnocení jeho celkové kvality.</p>
                                </div>
                            </div>
                            {watchAttribsSorted}
                        </div>
                        <div className="lecturetask-eval-box">
                            {graph}
                        </div>
                    </div>
                    </>
                );

            }
        }

        let profileInfoContent = 'Váš profil';
        if(typeof data.appModeData.profileId === 'number') {
            let fpid = data.appModeData.profileId;
            profileInfoContent = globals.profiles[fpid].name;
        }

        if(typeof data.appModeData.profileOverride === 'object') {
            profileInfoContent = data.appModeData.profileOverride.name;
        }


        //

        let topNavButtons = (
            <>
                <a className="accent" onClick={this.props.openSaveLectureTaskPanel}>{__('Uložit úkol')}</a>
                <a onClick={this.props.openLoadLectureTaskPanel}>{__('Načíst úkol')}</a>
            </>
        );

        // If this task is from UNIVERSAL COURSE, hide load/save buttons
        if(typeof this.props.data.appModeData.lectureInfoData.course_id === 'number') {
            if(this.props.data.appModeData.lectureInfoData.course_id === 46) {
                topNavButtons = false;
            }
        }

        // Seminar work and "ZADANI" mode? Hide load/save buttons
        // and show longterm evaluation button instead
        let swData = this.props.data.appModeData?.lectureInfoData?.seminar_work_data;
        if(typeof swData === 'object') { // && swData.is_assignment === 1) {


            if(swData.is_assignment === 1) {
                // If "ZADANI" mode, hide save/load buttons...
                if(this.props.data.user.id != 90) {
                    topNavButtons = (
                        <a className="accent" onClick={this.props.openSeminarWorkLongtermEvaluationPanel}>{__('Dlouhodobé vyhodnocení')}</a>
                    );
                } else {
                    // ... unless the logged-in user is 90 (MJ)
                    topNavButtons = (
                        <>
                            <a className="accent" onClick={this.props.openSaveLectureTaskPanel}>{__('Uložit úkol')}</a>
                            <a onClick={this.props.openLoadLectureTaskPanel}>{__('Načíst úkol')}</a>
                            <a className="accent" onClick={this.props.openSeminarWorkLongtermEvaluationPanel}>{__('Dlouhodobé vyhodnocení')}</a>
                        </>
                    );
                }
            } else {
                // If "VYPRACOVANI" mode, show all buttons (save/load/longterm eval)
                topNavButtons = (
                    <>
                        <a className="accent" onClick={this.props.openSaveLectureTaskPanel}>{__('Uložit úkol')}</a>
                        <a onClick={this.props.openLoadLectureTaskPanel}>{__('Načíst úkol')}</a>
                        <a className="accent" onClick={this.props.openSeminarWorkLongtermEvaluationPanel}>{__('Dlouhodobé vyhodnocení')}</a>
                    </>
                );
            }



        }


        const totalSteps = 2;
        let currentStep = 1;
        let currentStepTitle = __('Tvorba jídelníčku');
        let currentStepButton = <a onClick={this.props.openAssignmentInfo}>{__('Zobrazit zadání')}</a>;

        if(this.props.data.appSubMode == 'LECTURETASK_SAMPLE') {
            currentStep = 2;
            currentStepTitle = __('Vzorový jídelníček');
            currentStepButton = <a onClick={this.props.openLectureTaskSampleText}>{__('Komentář k vzorovému jídelníčku')}</a>
        }

        let dayTitle = <h1>{__('Úkol k lekci')}: <b>{this.props.lectureInfo.title}</b></h1>;

        let seminarWorkData = this.props.data.appModeData?.lectureInfoData?.seminar_work_data;
        if(typeof seminarWorkData === 'object') {
            
            let dayNames = {
                "Den 1": "Pondělí",
                "Den 2": "Úterý",
                "Den 3": "Středa",
                "Den 4": "Čtvrtek",
                "Den 5": "Pátek",
                "Den 6": "Sobota",
                "Den 7": "Neděle",
            };

            if(localStorage.getItem('zofapp_lang') == 'sk') {
                dayNames = {
                    "Den 1": "Pondelok",
                    "Den 2": "Utorok",
                    "Den 3": "Streda",
                    "Den 4": "Štvrtok",
                    "Den 5": "Piatok",
                    "Den 6": "Sobota",
                    "Den 7": "Nedeľa",
                };                    
            }

            let seminarDays = [];
            Object.keys(seminarWorkData.lectures).map(l => {
                let active = (this.props.data.appModeData.lectureId === l*1) ? 'bold' : 'normal';
                seminarDays.push(
                    <a style={{fontWeight:active}} onClick={(e) => this.seminarWorkChangeDay(l, e)}>{dayNames[seminarWorkData.lectures[l]]}</a>
                );
            });


            let dayTitleText = __('Úkol ke dni');
            seminarWorkData = this.props.data.appModeData?.lectureInfoData?.seminar_work_data;
            if(typeof seminarWorkData === 'object') {
                dayTitleText = __('Jídelníček');
            }
    

            dayTitle = (
                <>
                    <h1 style={{display:'flex'}}>
                        {dayTitleText}:
                        <div className="lecturetask-day-title">
                            <label htmlFor="dayTitleCb" style={{cursor:'pointer',textDecoration:'underline',color:'#26AA52'}}>
                                <b>{dayNames[this.props.lectureInfo.title]}</b>
                            </label>
                            <input type="checkbox" id="dayTitleCb" />
                            <div>
                                {seminarDays}
                            </div>
                        </div>
                    </h1>                
                </>
            );
        }

        /*
        if(typeof this.props.data.appModeData === 'object' &&
           typeof this.props.data.appModeData.
        

        let actualDay = <b>{this.props.lectureInfo.title}</b>
*/
        let firstGroup = (
            <div className="group">
                <div>
                    <p><b>Krok {currentStep}/{totalSteps} - {currentStepTitle}</b></p>
                    <p>
                        {currentStepButton}
                    </p>
                </div>
            </div>
        );



        let courseTitle = this.props.lectureInfo.course_title;

        if(typeof seminarWorkData === 'object') {
            firstGroup = false;
            courseTitle = 'Vypracování seminární práce';
            if(seminarWorkData.is_assignment === 1) {
                courseTitle = 'Zadání seminární práce';
            }
        }

        if(Util.getLang() == 'sk') {
            if(typeof seminarWorkData === 'object') {
                firstGroup = false;
                courseTitle = 'Vypracovanie seminárnej práce';
                if(seminarWorkData.is_assignment === 1) {
                    courseTitle = 'Zadanie seminárnej práce';
                }
            }
        }

        let content = (
            <div className="page page-bg page-plan">
                <div className="page-header" style={{padding:'0'}}>
                    <div className="container">
                        <div className="page-header-row">
                            <div className="header-group">
                                <h4 className="lecturetask-course-title">
                                    {courseTitle}
                                </h4>
                                {dayTitle}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-header" style={{padding:'0'}}>
                    <div className="container">
                        <div className="page-header-row" style={{paddingTop:'.5em'}}>
                            <div className="lecturetask-header-groups">
                                {firstGroup}
                                <div className="group">
                                    <div>
                                        <p><b>{__('Cílový profil')}:</b></p>
                                        <p>
                                            {profileInfoContent}
                                            <a style={{marginLeft:'.5rem'}} onClick={this.props.openProfileInfo}>{__('Více')}</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <aside>
                                {topNavButtons}
                            </aside>
                        </div>
                    </div>
                </div>


                <div className="page-content page-content-mobile-nav">
                    <div className="container" style={{paddingTop:'0'}}>
                        <input className="radio-plan-mobile-nav" name="plan-nav" type="radio" id="cb-plan-main" defaultChecked={true} />
                        <input className="radio-plan-mobile-nav" name="plan-nav" type="radio" id="cb-plan-evaluation" />
                        <div className="plan-mobile-nav">
                            <label htmlFor="cb-plan-main">Rozpis</label>
                            <label htmlFor="cb-plan-evaluation">Vyhodnocení</label>
                        </div>
                        <div className="plan">
                            <div className="plan-row">
                                <input checked="checked" className="radio-plan-mobile-nav" type="checkbox" id="cb-panel-evaluation" />
                                <div className="plan-main" style={{paddingTop:'2em',marginRight:'0em',paddingRight:'2.5em',borderRight:'1px solid #e0e4eb'}}>
                                    <div className="planner-hours">
                                        <div className="planner-top-nav">
                                            {this.props.viewTypeSwitch2}
                                        </div>
                                        {this.props.plan}
                                    </div>
                                </div>
                                <EvaluationPanel 
                                    beforeContent={evaluationPanelBeforeContent} 
                                    wholeDay={true} 
                                    quickResults={false}
                                    evaluationCollapsedAtStart={true}
                                    onRecommendedFoodAdded={this.onRecommendedFoodAdded} 
                                    mainStyle={{paddingTop:'2em'}}
                                    />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

        return content;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LectureTaskPlan));
