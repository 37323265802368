import shortid from 'shortid';

import {
    SET_DATA,
    LOADING_ON,
    LOADING_OFF,
    SET_USER,
    SET_APP_MODE,
    SET_APP_MODE_DATA,
    CLEAR_APP_MODE_DATA,
    SET_APP_SUBMODE,
    SET_MENU_CHANGED,
    SET_RECIPE_CHANGED,
    LOAD_GLOBALS,
    MERGE_GLOBALS,
    SET_GLOBAL,
    SHOW_MESSAGE,
    SET_LECTURETASK_DATA,       
    SET_LECTURETASK_COMPLETE,
    SET_LECTURETASK_COMPLETE_SAVED,
    SET_PROFILE_DATA,           // for lecturetask
    SET_PROFILE_ID,
    RESET_RECIPE,
    ADD_ACTIVITY,
    UPDATE_ACTIVITY,
    REMOVE_ACTIVITY,
    ADD_FOOD_GROUP,
    SET_FOOD_GROUP_TITLE,
    SET_FOOD_GROUP_DESCRIPTION,
    DELETE_EMPTY_FOOD_GROUPS,
    CHANGE_FOOD_GROUP_TYPE,
    OPEN_FOOD_GROUP,
    CLOSE_FOOD_GROUP,
    MOVE_FOOD_GROUP,
    REMOVE_FOOD_GROUP,
    MODIFY_FOOD_GROUP_AMOUNTS,
    MOVE_FOODS_TO_GROUP,
    ADD_FOOD,
    REMOVE_FOOD,
    SORT_FOOD,
    MOVE_FOOD_TO_PLATE,
    SET_FOOD_AMOUNT,
    SET_FOOD_AS_DRINK,
    ADD_FOOD_MODIFICATION,
    REMOVE_FOOD_MODIFICATION,    
    ASSIGN_STATE,
    LOAD_DAYPLAN,
    SET_PLANNER_EDIT_MODE,
    SET_ACTIVE_MEAL_HASH,
    SET_PLANNER_DATE,
    SET_EVALUATION_RESULTS,
    CLEAR_EVALUATION_RESULTS,
    SET_DAY_EVALUATION_RESULTS,
    OPEN_MODAL,
    CLOSE_MODAL,
    SET_MODAL_CONTENT,
    SET_RECIPE_TITLE,
    CLEAR_MEAL,
    ADD_MEAL,
    REMOVE_MEAL,
    UPDATE_MEAL,
    SET_MEAL_DESCRIPTION,      
    ADD_TAG,
    REMOVE_TAG,
    SET_TAGS,
    SET_TRAINING,
    ADD_TRAINING,
    UPDATE_TRAINING,
    REMOVE_TRAINING,
    SET_TRAINING_START,
    SET_TRAINING_LENGTH,
    SET_TRAINING_DESCRIPTION,
    COPY_DAY_CONTENTS,
    CLEAR_DAY_CONTENTS,
    SET_DAY_ENERGY_OUTPUT_COEFFICIENT,
    SET_DAY_ENERGY_OUTPUT_AMOUNT,
    SET_DAY_ENERGY_OUTPUT_AMOUNT_UNIT,
    SET_PLANNER_VIEW_MODE,
    SET_PRODUCT_DESCRIPTION,
    PROFI_SET_CLIENT_ID,            // ZOF Profi: set current client's ID (switch to his planner, basically)
} from "../actions/index";


const initialState = {

    // Basic (common) data

    globals: {},                    // Global app data (helpers, dictionaries, lists etc.) Loaded from API on start

    menuChanged: false,
    recipeChanged: false,

    appMode: 'DEFAULT',             // App mode (DEFAULT / LECTURE)
    appSubMode: '',                 // for LECTURE appMode, this could be either empty or "LECTURETASK_SAMPLE" for lecture's sample lecturetask mode (NO SAVING)
    appModeData: {},                // App mode data (depends on current mode, for example LECTURE mode needs a lecture ID)
    lectureTaskData: {},            // If lecture task mode, this holds the lecture task data
    lectureTaskComplete: false,     // Is lecture task completed?
    lectureTaskCompleteSaved: false,    // Is lecture task completed AND saved?

    user: false,                    // Logged in user's data (loaded after login)
    profileId: 0,                   // ID of current user's active profile

    plannerDate: false,             // (Date) current selected date in planner
    plannerViewMode: 0,             // 0 = full (with empty hours), 1 = compact (without empty hours)
    plannerEditMode: '',            // '' = generic, 'GASTRO_DAILY_MENU' = daily menu (gastro)

    meals: {},                      // Meals in recipe
    foodGroups: {},                 // Food groups
    foods: {},                      // Foods
    modifications: {},              // Food modifications
    titles: {},                     // Food groups titles
    descriptions: {},               // Food groups descriptions
    trainings: {},                  // Trainings during in day plan
    activities: {},                 // ATTN: not currently used
    energyOutputSettings: {},       // Energy output settings for current day in planner  
    evaluationResults: {},          // Current evaluation results combined from meals (for single recipe and whole day plan)
    mealsEvaluationResults: {},     // Current evaluation results for individual meals (not combined)
    activeMealHash: '',             // Hash of currently active meal (single recipe)

    modalOpened: false,             // Modal - is opened?
    modalTitle: '',                 // Title of modal
    modalClass: '',                 // Additional classes of modal window (if any)
    modalContent: false,            // Content of modal (JSX)
    modalOnClose: false,            // Callback to call when modal closes

    unreadNews: 0,                  // Number of unread news for current user

    // ZOF Gastro data

    tags: {},                       // Current recipe tags
    productDescription: '',         // Description of currently edited product

    // ZOF Profi data

    profiClientId: 0,               // ID of client the app is "switched" to (i.e. advisor can switch to one of his clients)

    // Misc data

    loading: true,                      // is app loading? (showing spinner when it is)

    // Helper data (not to be saved)

    lastAddedRecipeTitle: '',

    message: {
        show: false,
        type: 'info',           // info / error / success
        message: ''
    },

};


function saveCurrentRecipeToLocalStorage(state, actionType) {
    // We are saving just when in *single recipe* mode!
    // i.e. activeMealHash must be "1|0"
    if(state.activeMealHash !== '1|0') return;

    let unsavedRecipe = {
        'foodGroups': state.foodGroups,
        'foods': state.foods,
        'titles': state.titles,
        'descriptions': state.descriptions,
    };
    localStorage.setItem('zof_unsaved_recipe', JSON.stringify(unsavedRecipe));
}


function rootReducer(state = initialState, action) {
    let id;
    let newActivities;
    let foods;
    let modificationsArray;
    let resultFoodId;
    let foodId;
    let mealHash;
    let newGroupId;
    let hash;
    let newState = Object.assign({}, state);

    switch(action.type) {

        case SET_DATA:
            newState = Object.assign(newState, action.data);
            saveCurrentRecipeToLocalStorage(newState, action.type);
            return newState;

        case LOADING_ON:
            newState.loading = true;
            return newState;
            
        case LOADING_OFF:
            newState.loading = false;
            return newState;

        case SET_USER:
            newState.user = action.data;
            // newState.globals.user = action.data;
            return newState;

        case SET_APP_MODE:
            newState.appMode = action.data;
            return newState;

        case SET_APP_MODE_DATA:
            Object.keys(action.data).map(k => {
                newState.appModeData[k] = action.data[k];
            });
            return newState;

        case CLEAR_APP_MODE_DATA:
            newState.appModeData = {};
            return newState;
    
        case SET_APP_SUBMODE:
            newState.appSubMode = action.data;
            return newState;
    
        case SET_LECTURETASK_DATA:
            newState.lectureTaskData = action.data;
            return newState;

        case SET_LECTURETASK_COMPLETE:
            newState.lectureTaskComplete = action.data;
            return newState;

        case SET_LECTURETASK_COMPLETE_SAVED:
            newState.lectureTaskCompleteSaved = action.data;
            return newState;
    
        case SET_MENU_CHANGED:
            newState.menuChanged = action.data;
            return newState;

        case SET_RECIPE_CHANGED:
            newState.recipeChanged = action.data;
            return newState;
    
        case LOAD_GLOBALS:
            newState.globals = action.data;
            return newState;

        case MERGE_GLOBALS:
            newState.globals = Object.assign(newState.globals, action.data);
            return newState;

        case SET_GLOBAL:
            newState.globals[action.data.key] = action.data.value;
            return newState;

        case SHOW_MESSAGE:
            newState.message = action.data;
            return newState;

        case RESET_RECIPE:
            const lastAppMode = newState.appMode;
            const lastAppModeData = newState.appModeData;
            const lastLectureTaskData = newState.lectureTaskData;
            newState.menuChanged = true;
            newState.recipeChanged = true;
            newState = Object.assign({}, initialState);
            newState.appMode = lastAppMode;
            newState.appModeData = lastAppModeData;
            newState.user = state.user;
            newState.globals = state.globals;
            newState.activeMealHash = '1|0';
            newState.tags = [];
            newState.meals = {};
            newState.foodGroups = {};
            newState.foods = {};
            newState.modifications = {};
            newState.titles = {};
            newState.descriptions = {};
            newState.trainings = {};
            newState.activities = {};
            newState.lectureTaskData = lastLectureTaskData;
            return newState;

        case SET_PRODUCT_DESCRIPTION:
            newState.productDescription = action.data;
            return newState;

        case PROFI_SET_CLIENT_ID:
            newState.profiClientId = action.data;
            return newState;


        // OLD reducer methods


        case ADD_ACTIVITY:
            newState = Object.assign({}, state);
            newState.menuChanged = true;
            id = action.data.id;
            hash = action.data.activityId + '|' + action.data.profiles.sort((a, b) => a - b).join(',');
            newState.activities[id] = {
                hash: hash,
                activityId: action.data.activityId,
                startHour: action.data.startHour,
                startMinute: action.data.startMinute,
                length: action.data.length,
                profiles: action.data.profiles
            };
            return newState;

        case UPDATE_ACTIVITY:
            newState = Object.assign({}, state);
            newState.menuChanged = true;

            newActivities = [];
            newState.activities.map(id => {
                if (newState.activities[id].id != action.data.id) newActivities.push(newState.activities[id]);
            });
            newState.activities.push({
                id: action.data.id,
                fromHour: action.data.fromHour,
                fromMinute: action.data.fromMinute,
                toHour: action.data.toHour,
                toMinute: action.data.toMinute
            });
            return newState;

        case REMOVE_ACTIVITY:
            newState = Object.assign({}, state);
            newState.menuChanged = true;

            newActivities = [];
            newState.activities.map(act => {
                if (act.id != action.data) newActivities.push(act);
            });
            newState.activities = newActivities;
            return newState;

        case ADD_FOOD_GROUP:
            newState = Object.assign({}, state);
            newState.menuChanged = true;
            newState.recipeChanged = true;

            mealHash = state.activeMealHash;
            if(typeof action.data.mealHash !== 'undefined') {
                mealHash = action.data.mealHash;                
            }

            if (typeof newState.foodGroups[mealHash] == "undefined") {
                newState.foodGroups[mealHash] = {};
            }

            Object.keys(newState.foodGroups[mealHash]).map(g => {
                newState.foodGroups[mealHash][g].opened = false;
            });

            let groupKeys = Object.keys(newState.foodGroups[mealHash]);
            let groupsNumber = groupKeys.length;
            action.data.item_order = groupsNumber + 1;

            newState.foodGroups[mealHash][action.data.id] = action.data;

            saveCurrentRecipeToLocalStorage(newState, action.type);
            return newState;

        case SET_FOOD_GROUP_TITLE:
            newState = Object.assign({}, state);
            newState.menuChanged = true;
            newState.recipeChanged = true;

            mealHash = state.activeMealHash;
            if(typeof action.data.mealHash !== 'undefined') {
                mealHash = action.data.mealHash;
            }
            if (typeof newState.foodGroups[mealHash] == "undefined") {
                return newState;
            }
            for (let g in newState.foodGroups[mealHash]) {
                if (newState.foodGroups[mealHash][g].id == action.data.id) {
                    newState.foodGroups[mealHash][g].title = action.data.title;
                }
            }

            saveCurrentRecipeToLocalStorage(newState, action.type);
            return newState;

        case SET_FOOD_GROUP_DESCRIPTION:
            newState = Object.assign({}, state);
            newState.menuChanged = true;
            newState.recipeChanged = true;

            if(typeof action.data.mealHash === 'string') {
                mealHash = action.data.mealHash;
            } else {
                mealHash = state.activeMealHash;
            }


            if (typeof newState.foodGroups[mealHash] == "undefined") {
                return newState;
            }
            for (let g in newState.foodGroups[mealHash]) {
                if (newState.foodGroups[mealHash][g].id == action.data.id) {
                    newState.foodGroups[mealHash][g].description = action.data.description;
                }
            }

            saveCurrentRecipeToLocalStorage(newState, action.type);
            return newState;

        case DELETE_EMPTY_FOOD_GROUPS:
            newState = Object.assign({}, state);
            newState.menuChanged = true;
            newState.recipeChanged = true;

            mealHash = state.activeMealHash;
            if(typeof action.data === 'object') {
                mealHash = action.data.mealHash;
            }
            let groupsToDelete = [];
            for(let g in newState.foodGroups[mealHash]) {
                let groupIsEmpty = true;
                for(let f in newState.foods[mealHash]) {
                    if(newState.foods[mealHash][f].group_id == g) {
                        groupIsEmpty = false;
                        break;
                    }
                }
                if(groupIsEmpty) groupsToDelete.push(g);
            }

            groupsToDelete.map(groupId => {
                delete newState.foodGroups[mealHash][groupId];
                if (Array.isArray(newState.foods[mealHash])) {
                    newState.foods[mealHash] = newState.foods[mealHash].filter(food => food.group_id != groupId);
                }
            });

            saveCurrentRecipeToLocalStorage(newState, action.type);
            return newState;

        case CHANGE_FOOD_GROUP_TYPE:
            newState = Object.assign({}, state);
            newState.menuChanged = true;
            newState.recipeChanged = true;

            mealHash = state.activeMealHash;
            if(typeof action.data.mealHash !== 'undefined') {
                mealHash = action.data.mealHash;
            }

            if (typeof newState.foodGroups[mealHash] == "undefined") {
                return newState;
            }
            for (let g in newState.foodGroups[mealHash]) {
                if (newState.foodGroups[mealHash][g].id == action.data.id) {
                    newState.foodGroups[mealHash][g].type = action.data.type;
                }
            }

            saveCurrentRecipeToLocalStorage(newState, action.type);
            return newState;

        case OPEN_FOOD_GROUP:
            mealHash = state.activeMealHash;
            newState = Object.assign({}, state);
            if (typeof newState.foodGroups[mealHash] == "undefined") {
                return newState;
            }
            for (let g in newState.foodGroups[mealHash]) {
                if (newState.foodGroups[mealHash][g].id == action.data.id) {
                    newState.foodGroups[mealHash][g].opened = true;
                }
            }

            saveCurrentRecipeToLocalStorage(newState, action.type);
            return newState;

        case CLOSE_FOOD_GROUP:
            mealHash = state.activeMealHash;
            newState = Object.assign({}, state);
            if (typeof newState.foodGroups[mealHash] == "undefined") {
                return newState;
            }
            for (let g in newState.foodGroups[mealHash]) {
                if (newState.foodGroups[mealHash][g].id == action.data.id) {
                    newState.foodGroups[mealHash][g].opened = false;
                }
            }

            saveCurrentRecipeToLocalStorage(newState, action.type);
            return newState;

        case MOVE_FOOD_GROUP:
            mealHash = newState.activeMealHash;
            let newFoodGroups = {};

            if(typeof action.data.mealHash !== 'undefined') {
                mealHash = action.data.mealHash;
            }

            let itemOrderSrc = newState.foodGroups[mealHash][action.data.id].item_order;
            let itemOrderDst = itemOrderSrc + action.data.direction * 1;

            const keys = Object.keys(newState.foodGroups[mealHash]);
            if(itemOrderDst < 1 || itemOrderDst > keys.length) return newState;

            for(let g in newState.foodGroups[mealHash]) {
                let group = newState.foodGroups[mealHash][g];
                if(group.item_order == itemOrderSrc) {
                    group.item_order = itemOrderDst;
                    newFoodGroups[g] = group;
                } else if (group.item_order == itemOrderDst) {
                    group.item_order = itemOrderSrc;
                    newFoodGroups[g] = group;
                } else {
                    newFoodGroups[g] = group;
                }
            }          

            newState.menuChanged = true;
            newState.recipeChanged = true;
            newState.foodGroups[mealHash] = newFoodGroups;
            saveCurrentRecipeToLocalStorage(newState, action.type);
            return newState;

        case REMOVE_FOOD_GROUP:
            newState = Object.assign({}, state);
            newState.menuChanged = true;
            newState.recipeChanged = true;

            mealHash = state.activeMealHash;
            if(typeof action.data.mealHash !== 'undefined') {
                mealHash = action.data.mealHash;
            }


            if (typeof newState.foodGroups[mealHash] == "undefined") {
                return newState;
            }
            for (let g in newState.foodGroups[mealHash]) {
                if (newState.foodGroups[mealHash][g].id == action.data.id) {
                    delete newState.foodGroups[mealHash][g];
                }
            }
            if(Array.isArray(newState.foods[mealHash])) {
                newState.foods[mealHash] = newState.foods[mealHash].filter(food => food.group_id != action.data.id);
            }

            if(typeof newState.titles[mealHash] !== 'undefined') {
                delete newState.titles[mealHash];
            }

            // Create new empty food group is there is none (there always MUST be at least one!)
            let groupsCount = 0;
            for (let g in newState.foodGroups[mealHash]) {
                groupsCount++;
            }

            // If last group remaining, make it open
            if(groupsCount == 1) {
                for (let g in newState.foodGroups[mealHash]) {
                    newState.foodGroups[mealHash][g].opened = true;
                }
            }

            // If no groups left, create a new empty one
            if(groupsCount == 0) {
                let newGroupId = shortid();
                newState.foodGroups[mealHash][newGroupId] = {
                    id: newGroupId,
                    type: 'plate',
                    title: '',
                    opened: true
                };
            }

            saveCurrentRecipeToLocalStorage(newState, action.type);
            return newState;

        case MODIFY_FOOD_GROUP_AMOUNTS:
            newState = Object.assign({}, state);
            newState.menuChanged = true;
            newState.recipeChanged = true;

            mealHash = state.activeMealHash;
            if(typeof action.data.mealHash !== 'undefined') {
                mealHash = action.data.mealHash;
            }
            
            let coef = action.data.coefficient;
            if (Array.isArray(newState.foods[mealHash])) {
                let foods = newState.foods[mealHash];
                foods.map(food => {
                    let result = food.weight * (coef / 100.0);
                    result = parseFloat((parseFloat(result).toPrecision(4)));
                    if (food.group_id == action.data.group_id) {
                        food.weight = result;
                    }
                });
                newState.foods[mealHash] = foods;
            }
            saveCurrentRecipeToLocalStorage(newState, action.type);
            return newState;

        case MOVE_FOODS_TO_GROUP:
            newState = Object.assign({}, state);
            newState.menuChanged = true;
            newState.recipeChanged = true;

            mealHash = state.activeMealHash;
            newState.foods[mealHash].map(food => {
                if(typeof food.group_id !== 'undefined') {
                    if(food.group_id == action.data.from) {
                        food.group_id = action.data.to;
                    }
                } else {
                    food.group_id = action.data.to;
                }
                return food;
            });
            saveCurrentRecipeToLocalStorage(newState, action.type);
            return newState;

        case ADD_FOOD:
            newState = Object.assign({}, state);
            newState.menuChanged = true;
            newState.recipeChanged = true;

            if(typeof action.data.mealHash === 'string') {
                mealHash = action.data.mealHash;
            } else {
                mealHash = state.activeMealHash;
            }


            if (typeof newState.foods[mealHash] == "undefined") {
                newState.foods[mealHash] = [];
            }
            newState.foods[mealHash] = [...state.foods[mealHash], action.data];
            saveCurrentRecipeToLocalStorage(newState, action.type);
            return newState;

        case REMOVE_FOOD:
            newState = Object.assign({}, state);
            newState.menuChanged = true;
            newState.recipeChanged = true;

            if(typeof action.data === 'object') {
                mealHash = action.data.mealHash;
                newState.foods[mealHash] = newState.foods[mealHash].filter(obj => {
                    return obj.id !== action.data.id;
                });
            } else {
                mealHash = state.activeMealHash;
                newState.foods[mealHash] = newState.foods[mealHash].filter(obj => {
                    return obj.id !== action.data
                });
            }
            saveCurrentRecipeToLocalStorage(newState, action.type);
            return newState;

        case SORT_FOOD:
            newState = Object.assign({}, state);
            newState.menuChanged = true;
            newState.recipeChanged = true;

            mealHash = state.activeMealHash;
            if(typeof action.data.mealHash === 'string') {
                mealHash = action.data.mealHash;
            }

            foods = newState.foods[mealHash];

            // foods.sort((a, b) => (a.group_id < b.group_id) ? -1 : 1);   

            let groupId = '';
            let index = -1;
            foods.map((food, key) => {
                if (food.id == action.data.foodId) {
                    index = key;
                    groupId = food.group_id;
                }
            });
            if (index < 0) return newState;

            let indexNew = index + action.data.direction;
            let minKey = 999999, maxKey = -1;
            let destKey = -1, destKeyDist = 999999;

            foods.map((food, key) => {
                if (food.group_id === groupId) {
                    if (key > maxKey) {
                        maxKey = key;
                    }
                    if (key < minKey) {
                        minKey = key;
                    }

                    if(action.data.direction > 0) {
                        const dist = key - index;
                        if(dist > 0 && dist < destKeyDist) {
                            destKeyDist = dist;
                            destKey = key;
                        }
                    } else {
                        const dist = index - key;
                        if(dist > 0 && dist < destKeyDist) {
                            destKeyDist = dist;
                            destKey = key;
                        }
                    }
                }
            });

            if (indexNew < minKey || indexNew > maxKey) return newState;
            if(destKey < 0) return newState;

            let tmp = foods[index];
            foods[index] = foods[destKey];
            foods[destKey] = tmp;
            newState.foods[mealHash] = foods;
            saveCurrentRecipeToLocalStorage(newState, action.type);
            return newState;

        case MOVE_FOOD_TO_PLATE:

            if(typeof action.data.mealHash !== 'undefined') {
                mealHash = action.data.mealHash;
            } else {
                mealHash = state.activeMealHash;
            }
            
            foods = newState.foods[mealHash];
            foods.map((food, key) => {
                if(food.id == action.data.foodId) {
                    food.group_id = action.data.foodGroupId;
                }
            });

            newState.menuChanged = true;
            newState.recipeChanged = true;
            newState.foods[mealHash] = foods;
            saveCurrentRecipeToLocalStorage(newState, action.type);
            return newState;

        case SET_FOOD_AMOUNT:
            newState = Object.assign({}, state);
            newState.menuChanged = true;
            newState.recipeChanged = true;

            foods = newState.foods[action.data.mealHash];
            foods.map((food, key) => {
                if (food.id == action.data.foodId) {
                    food.weight = action.data.weight;
                }
            });
            newState.foods[action.data.mealHash] = foods;
            saveCurrentRecipeToLocalStorage(newState, action.type);
            return newState;

        case SET_FOOD_AS_DRINK:
            newState = Object.assign({}, state);
            newState.menuChanged = true;
            newState.recipeChanged = true;

            foods = newState.foods[action.data.mealHash];
            foods.map((food, key) => {
                if (food.id == action.data.foodId) {
                    food.is_drink = action.data.is_drink;
                }
            });
            newState.foods[action.data.mealHash] = foods;
            saveCurrentRecipeToLocalStorage(newState, action.type);
            return newState;

        case ADD_FOOD_MODIFICATION:
            newState = Object.assign({}, state);
            newState.menuChanged = true;
            newState.recipeChanged = true;

            action.data.modificationId = 1 * action.data.modificationId;
            foodId = action.data.foodId;
            if (typeof newState.modifications[foodId] === 'undefined') {
                newState.modifications[foodId] = [];
            }
            let count = newState.modifications[foodId].length;
            if (count < 999) {
                newState.modifications[foodId] = newState.modifications[foodId].filter(e => e !== action.data.modificationId);
                newState.modifications[foodId] = [...newState.modifications[foodId], action.data.modificationId];
            }
            saveCurrentRecipeToLocalStorage(newState, action.type);
            return newState;

        case REMOVE_FOOD_MODIFICATION:
            newState = Object.assign({}, state);
            newState.menuChanged = true;
            newState.recipeChanged = true;

            action.data.modificationId = 1 * action.data.modificationId;
            modificationsArray = [action.data.modificationId];
            resultFoodId = action.data.foodId;
            for (foodId in newState.modifications) {
                if (foodId = action.data.foodId) {
                    resultFoodId = foodId;
                    modificationsArray = newState.modifications[foodId];
                }
            }
            modificationsArray = modificationsArray.filter(e => e !== action.data.modificationId);
            newState.modifications[resultFoodId] = modificationsArray;
            saveCurrentRecipeToLocalStorage(newState, action.type);
            return newState;

        case ASSIGN_STATE:
            newState = Object.assign({}, state);
            newState = Object.assign(newState, action.data);
            return newState;

        case LOAD_DAYPLAN:
            newState = Object.assign({}, state);
            newState.menuChanged = false;
            newState.recipeChanged = false;

            newState.activeMealHash = '';
            newState.meals = {};
            newState.foodGroups = {};
            newState.foods = {};
            newState.modifications = {};
            newState.titles = {};
            newState.descriptions = {};
            newState.activities = {};
            newState.energyOutputSettings = {};
            newState.trainings = {};

            if (typeof action.data.empty !== 'undefined') return newState;

            // Set meals

            for (mealHash in action.data.state.meals) {
                let meal = action.data.state.meals[mealHash];
                let profileIds = meal.profile_ids.split(',').map(profileId => profileId * 1);

                if(state.appMode === 'LECTURE') {
                    if(typeof state.appModeData.profileId === 'number') {
                        profileIds = [state.appModeData.profileId];

                    }
                }

                let mealTime = meal.meal_time.split(':');
                newState.meals[mealHash] = {
                    mealId: meal.meal_id,
                    profiles: profileIds,
                    startHour: mealTime[0] * 1,
                    startMinute: mealTime[1] * 1,
                    profi_comment: meal.profi_comment
                }

                newState.titles[mealHash] = meal.title;
                newState.descriptions[mealHash] = meal.description;

                // Create food groups, if any
                newState.foodGroups[mealHash] = {};
                for(let groupKey in action.data.state.groups[mealHash]) {
                    newState.foodGroups[mealHash][groupKey] = {
                        id: groupKey,
                        type: action.data.state.groups[mealHash][groupKey].type,
                        title: action.data.state.groups[mealHash][groupKey].title,
                        description: action.data.state.groups[mealHash][groupKey].description,
                        item_order: action.data.state.groups[mealHash][groupKey].item_order,
                        from_recipe_id: action.data.state.groups[mealHash][groupKey].from_recipe_id,
                        gastro_data: action.data.state.groups[mealHash][groupKey].gastro_data,
                        opened: true
                    };
                }

                // Set meal foods
                newState.foods[mealHash] = [];
                for (let foodKey in action.data.state.foods[mealHash]) {
                    let food = action.data.state.foods[mealHash][foodKey];
                    if(typeof newState.foods[mealHash] == "undefined") newState.foods[mealHash] = {};

                    let foodStruct = {
                        id: food.internal_id,
                        food_id: food.item_id,
                        group_id: food.group_id,
                        image: "https://www.zofapp.cz/data/zof/item/" + food.item_id + ".svg",
                        title: food.title,
                        weight: food.weight,
                        is_drink: food.is_drink
                    };
                    newState.foods[mealHash] = [...newState.foods[mealHash], foodStruct];
                }

            }

            // Set food modifications
            for(mealHash in action.data.state.modifications) {
                let modification = action.data.state.modifications[mealHash];
                for(foodId in modification) {
                    modification[foodId].map(modId => {
                        if (typeof newState.modifications[foodId] == "undefined") {
                            newState.modifications[foodId] = [];
                        }
                        newState.modifications[foodId] = [...newState.modifications[foodId], modId];
                    });
                }
            }

            // Set energy output

            newState.energyOutputSettings = {};
            for (let profileId in action.data.state.energyOutputSettings) {
                let exp = action.data.state.energyOutputSettings[profileId];
                newState.energyOutputSettings[profileId] = {
                    coefficientId: exp.coefficientId,
                    amount: exp.amount,
                    unit: exp.unit
                };
            }

            // Set trainings

            for (let trainingId in action.data.state.trainings) {
                trainingId = trainingId * 1;
                let t = action.data.state.trainings[trainingId];
                newState.trainings[trainingId] = {
                    id: trainingId,
                    start: t.start,
                    length: t.length,
                    description: t.description
                };
            }
            return newState;

        case SET_PLANNER_EDIT_MODE:
            newState = Object.assign({}, state);
            newState.plannerEditMode = action.data;
            return newState;
        
        case SET_ACTIVE_MEAL_HASH:
            newState = Object.assign({}, state);
            newState.activeMealHash = action.data;
            return newState;

        case SET_PLANNER_DATE:
            newState = Object.assign({}, state);
            newState.plannerDate = action.data;
            return newState;

        case SET_EVALUATION_RESULTS:
            newState = Object.assign({}, state);
            newState.evaluationResults = action.data;
            return newState;

        case CLEAR_EVALUATION_RESULTS:
            newState = Object.assign({}, state);
            newState.evaluationResults = {};
            newState.mealsEvaluationResults = {};
            return newState;

        case SET_DAY_EVALUATION_RESULTS:
            newState = Object.assign({}, state);
            newState.evaluationResults = action.data.results;
            newState.mealsEvaluationResults = action.data.mealsResults;
            return newState;

        case OPEN_MODAL:
            newState = Object.assign({}, state);

            newState.modalClass = '';
            if(typeof action.data.class !== 'undefined') newState.modalClass = action.data.class;

            newState.closeButton = '';
            if(typeof action.data.closeButton !== 'undefined') newState.closeButton = action.data.closeButton;

            newState.modalTitle = action.data.title;
            newState.modalContent = action.data.content;

            newState.modalOnClose = false;
            if(typeof action.data.onClose !== 'undefined') {
                newState.modalOnClose = action.data.onClose;
            }

            newState.modalOpened = true;
            return newState;

        case CLOSE_MODAL:
            if(typeof state.modalOnClose == 'function') state.modalOnClose();
            newState = Object.assign({}, state);
            newState.modalOpened = false;
            newState.modalOnClose = false;
            return newState;

        case SET_MODAL_CONTENT:
            newState = Object.assign({}, state);
            newState.modalContent = action.data;
            return newState;


        case SET_RECIPE_TITLE:
            newState = Object.assign({}, state);
            newState.menuChanged = true;
            newState.recipeChanged = true;

            newState.titles[action.data.mealHash] = action.data.title;
            saveCurrentRecipeToLocalStorage(newState, action.type);
            return newState;

        case CLEAR_MEAL:
            newState = Object.assign({}, state);
            newState.menuChanged = true;
            newState.recipeChanged = true;

            newState.foods[action.data.mealHash] = [];
            newState.titles[action.data.mealHash] = '';
            return newState;

        case ADD_MEAL:
            newState = Object.assign({}, state);
            newState.menuChanged = true;

            let hashProfilesPart = action.data.profiles.sort((a, b) => a - b).join(',');

            // Create meal hash based on meal type

            if(action.data.mealId == 50) {
                // Pitny rezim
                // vygenerovat hash (jeho prvni cast) na zaklade uz existujicich pitnych rezimu
                // ID range 50 - 69
                let newMealType = 50;
                let newMealHash = newMealType + '|' + hashProfilesPart;
                while(typeof newState.meals[newMealHash] !== 'undefined') {
                    newMealType++;
                    if(newMealType > 69) break;
                    newMealHash = newMealType + '|' + hashProfilesPart;
                }

                if (typeof newState.meals[newMealHash] === 'undefined') {
                    hash = newMealHash;
                } else {
                    return newState;
                }

            } else {
                // Bezny chod (jidlo)
                hash = action.data.mealId + '|' + hashProfilesPart;
            }

            if (typeof newState.meals[hash] !== 'undefined') {
                //alert("Tento chod s vybranými profily už máte přidán.");
                return newState;
            }

            action.data.profiles.map(profileId => profileId * 1);

            newState.meals[hash] = {
                mealId: action.data.mealId,
                startHour: action.data.startHour,
                startMinute: action.data.startMinute,
                profiles: action.data.profiles
            };

            newGroupId = shortid();
            if(typeof newState.foodGroups[hash] == 'undefined') {
                newState.foodGroups[hash] = {};
            }

            
            let createDefaultFoodGroup = true;
            /*
            if (ZOFSession.get('action') !== 'undefined') {
                if (ZOFSession.get('action') == 'OPEN_RECIPE') {
                    createDefaultFoodGroup = false;
                }
            }
            */

            if(createDefaultFoodGroup) {
                newState.foodGroups[hash][newGroupId] = {
                    id: newGroupId,
                    type: 'plate',
                    title: '',
                    opened: true
                };
            }

            if (typeof newState.descriptions[hash] == "undefined") newState.descriptions[hash] = "";
            if (typeof newState.titles[hash] == "undefined") newState.titles[hash] = "";
            if (typeof newState.foods[hash] == "undefined") newState.foods[hash] = [];
            return newState;

        case REMOVE_MEAL:
            newState = Object.assign({}, state);
            newState.menuChanged = true;

            delete newState.meals[action.data];
            delete newState.foods[action.data];
            delete newState.foodGroups[action.data];
            delete newState.modifications[action.data];
            delete newState.titles[action.data];
            delete newState.descriptions[action.data];
            newState.activeMealHash = '';
            return newState;

        case UPDATE_MEAL:
            newState = Object.assign({}, state);
            newState.menuChanged = true;

            action.data.profiles.map(profileId => profileId * 1);

            let mealRecord = newState.meals[action.data.mealHash];
            delete newState.meals[action.data.mealHash];

            let newHash = action.data.mealId + '|' + action.data.profiles.sort((a, b) => a - b).join(',');

            newState.meals[newHash] = mealRecord;
            newState.meals[newHash].mealId = action.data.mealId * 1;
            newState.meals[newHash].startHour = action.data.startHour;
            newState.meals[newHash].startMinute = action.data.startMinute;
            newState.meals[newHash].profiles = action.data.profiles;
            
            let foodsToCopy = newState.foods[action.data.mealHash];
            delete newState.foods[action.data.mealHash];
            newState.foods[newHash] = foodsToCopy;

            let titlesToCopy = newState.titles[action.data.mealHash];
            delete newState.titles[action.data.mealHash];
            newState.titles[newHash] = titlesToCopy;

            let descriptionsToCopy = newState.descriptions[action.data.mealHash];
            delete newState.descriptions[action.data.mealHash];
            newState.descriptions[newHash] = descriptionsToCopy;

            let foodGroupsToCopy = newState.foodGroups[action.data.mealHash];
            delete newState.foodGroups[action.data.mealHash];
            newState.foodGroups[newHash] = foodGroupsToCopy;
            
            return newState;

        case SET_MEAL_DESCRIPTION:
            newState = Object.assign({}, state);
            newState.menuChanged = true;
            newState.recipeChanged = true;

            newState.descriptions[action.data.mealHash] = action.data.description;
            return newState;

        case ADD_TAG:
            mealHash = state.activeMealHash;
            newState.menuChanged = true;
            newState.recipeChanged = true;

            newState = Object.assign({}, state);
            newState.tags[mealHash].push(action.data);
            return newState;

        case REMOVE_TAG:
            mealHash = state.activeMealHash;
            newState.menuChanged = true;
            newState.recipeChanged = true;

            newState = Object.assign({}, state);
            newState.tags[mealHash].filter(tagId => {
                return tagId !== action.data
            });
            return newState;

        case SET_TAGS:
            newState = Object.assign({}, state);
            newState.menuChanged = true;
            newState.recipeChanged = true;

            mealHash = state.activeMealHash;
            newState.tags[mealHash] = action.data;
            return newState;

        case SET_PROFILE_DATA:
            newState = Object.assign({}, state);
            newState.globals.profiles[action.data.id] = action.data.data; 
            return newState;
                
        case SET_PROFILE_ID:
            newState = Object.assign({}, state);
            newState.profileId = action.data * 1;
            return newState;

        case COPY_DAY_CONTENTS:
            return newState;

        case CLEAR_DAY_CONTENTS:
            return newState;

        case SET_DAY_ENERGY_OUTPUT_COEFFICIENT:
            newState = Object.assign({}, state);
            newState.menuChanged = true;
    
            if(typeof newState.energyOutputSettings[action.data.profileId] == 'undefined') {
                newState.energyOutputSettings[action.data.profileId] = {coefficientId: -1, amount: 0, 'unit': 'kcal'};
            }
            newState.energyOutputSettings[action.data.profileId].coefficientId = action.data.coefficientId * 1;
            newState.energyOutputSettings[action.data.profileId].amount = 0;
            return newState;

        case SET_DAY_ENERGY_OUTPUT_AMOUNT:
            newState = Object.assign({}, state);
            newState.menuChanged = true;

            if (typeof newState.energyOutputSettings[action.data.profileId] == 'undefined') {
                newState.energyOutputSettings[action.data.profileId] = { coefficientId: -1, amount: 0, 'unit': 'kcal' };
            }
            newState.energyOutputSettings[action.data.profileId].amount = action.data.amount * 1;
            newState.energyOutputSettings[action.data.profileId].coefficientId = -1;
            return newState;

        case SET_DAY_ENERGY_OUTPUT_AMOUNT_UNIT:
            newState = Object.assign({}, state);
            newState.menuChanged = true;

            if (typeof newState.energyOutputSettings[action.data.profileId] == 'undefined') {
                newState.energyOutputSettings[action.data.profileId] = { coefficientId: -1, amount: 0, 'unit': 'kcal' };
            }
            newState.energyOutputSettings[action.data.profileId].unit = action.data.unit;
            newState.energyOutputSettings[action.data.profileId].coefficientId = -1;
            return newState;

        case SET_TRAINING:
            newState = Object.assign({}, state);
            newState.menuChanged = true;

            newState.trainings[action.data.trainingNumber] = {
                id: action.data.trainingNumber,
                start: action.data.start,
                length: action.data.length,
                description: action.data.description
            };
            return newState;

        case ADD_TRAINING:
            newState = Object.assign({}, state);
            newState.menuChanged = true;

            let maxTrainingId = 0;
            Object.keys(newState.trainings).map(k => {
                const t = newState.trainings[k];
                if(t.id > maxTrainingId) maxTrainingId = t.id;
            });

            action.data['id'] = maxTrainingId * 1 + 1;
            newState.trainings[action.data['id']] = action.data;
            return newState;

        case UPDATE_TRAINING:
            newState = Object.assign({}, state);
            newState.menuChanged = true;

            let resultKey = -1;
            Object.keys(newState.trainings).map(k => {
                if(action.data.id == k) {
                    resultKey = k;
                }
            });
            if(resultKey >= 0) {
                newState.trainings[resultKey] = {
                    id: resultKey,
                    start: action.data.startHour + ':' + action.data.startMinute,
                    length: action.data.length,
                    description: action.data.description
                };             
            }

            return newState;

        case SET_TRAINING_START:
            newState = Object.assign({}, state);
            newState.menuChanged = true;

            newState.trainings[action.data.id].id = action.data.id;
            newState.trainings[action.data.id].start = action.data.start;
            return newState;

        case SET_TRAINING_LENGTH:
            newState = Object.assign({}, state);
            newState.menuChanged = true;

            newState.trainings[action.data.id].id = action.data.id;
            newState.trainings[action.data.id].length = action.data.length;
            return newState;

        case SET_TRAINING_DESCRIPTION:
            newState = Object.assign({}, state);
            newState.menuChanged = true;

            action.data.id = action.data.id * 1;
            newState.trainings[action.data.id].id = action.data.id;
            newState.trainings[action.data.id].description = action.data.description;
            return newState;

        case REMOVE_TRAINING:
            newState = Object.assign({}, state);
            newState.menuChanged = true;

            let resultTrainings = {};
            Object.keys(newState.trainings).map(k => {
                if(k != action.data) {
                    resultTrainings[k] = newState.trainings[k];
                }
            });
            newState.trainings = resultTrainings;
            return newState;

        case SET_PLANNER_VIEW_MODE:
            newState = Object.assign({}, state);
            newState.plannerViewMode = action.data;
            return newState;

        //

        default:
            return newState;
    }
};

export default rootReducer;
